html {
  background-color: var(--chakra-colors-brand-500);
}

html,
body {
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}
